import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Button, Typography } from "@mui/material";
import MyTable from "../../components/tables/Table";
import { openModal } from "../../features/modalSlice";

const Employees = () => {
	const dispatch = useDispatch();

	// Example columns
	const employeesTableColumns = [
		{ dataKey: "name", title: "Name", width: 150 },
		{ dataKey: "email", title: "Email", width: 200 },
		{ dataKey: "phone", title: "Phone Number", width: 150 },
		{ dataKey: "role", title: "Role", width: 200 },
	];

	// const tableData = [
	// 	{
	// 		name: "Lucas Belos",
	// 		email: "lbelo@selectionspa.com",
	// 		phone: "888-888-8888",
	// 		role: "Analyst",
	// 	},
	// 	{
	// 		name: "Tiffany Hulowich",
	// 		email: "th@selectionspa.com",
	// 		phone: "888-888-8888",
	// 		role: "Analyst",
	// 	},
	// ];

	useEffect(() => {
		// Get employees
	}, []);

	return (
		<div className="employees">
			<div className="employees_heading">
				<Typography variant="h5">Empoyees</Typography>
				<Button
					onClick={() => dispatch(openModal("NEW_EMPLOYEE"))}
					className="add_employee-button"
					variant="contained"
				>
					Add
				</Button>
			</div>
			<MyTable data={[]} columns={employeesTableColumns} loading={false} />
		</div>
	);
};

export default Employees;
