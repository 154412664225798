import { createSlice } from "@reduxjs/toolkit";
import jwt_decode from "jwt-decode";

const initialState = {
	user: null,
	isAuthenticated: false,
	isLoading: false,
};

const authSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		setCredentials: (state, action) => {
			const { accessToken, refreshToken } = action.payload;
			const decoded = jwt_decode(accessToken);
			console.log("payload", decoded);

			state.user = decoded.user;
			state.isAuthenticated = decoded.isAuthenticated;
			state.isLoading = false;

			localStorage.setItem("accessToken", accessToken);
			localStorage.setItem("refreshToken", refreshToken);
		},

		logout: (state) => {
			// Reset state
			state.user = null;
			state.isAuthenticated = false;
			state.isLoading = true;

			// clear local and session storage
			localStorage.clear();
			sessionStorage.clear();
		},

		initializeFromToken: (state, action) => {
			const decoded = jwt_decode(localStorage.getItem("refreshToken"));
			console.log(decoded);

			state.user = decoded.user;
			state.isAuthenticated = decoded.isAuthenticated;
			state.isLoading = false;
		},
	},
});

export const { setCredentials, logout, initializeFromToken } =
	authSlice.actions;

export const selectUser = (state) => {
	return state.auth;
};

export default authSlice.reducer;
