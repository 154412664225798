import { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import NewAppointment from "./NewAppointment";
import { Backdrop } from "@mui/material";
import { selectModal, closeModal } from "../../features/modalSlice";
import NewEmployee from "./NewEmployee";
import NewCustomer from "./NewCustomer";

const Modal = () => {
	const { isOpen, type } = useSelector(selectModal);
	const modalRef = useRef();
	const dispatch = useDispatch();

	useEffect(() => {
		const handleCloseModal = (e) => {
			if (modalRef.current && !modalRef.current.contains(e.target)) {
				dispatch(closeModal());
			}
		};

		document.addEventListener("mousedown", handleCloseModal);

		return () => document.removeEventListener("mousedown", handleCloseModal);
	});

	return (
		<Backdrop
			className="modal_overlay"
			sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
			open={isOpen}
			// onClick={handleClose}
		>
			<div ref={modalRef} className="container">
				{type === "NEW_APPOINTMENT" ? (
					<NewAppointment />
				) : type === "NEW_CUSTOMER" ? (
					<NewCustomer />
				) : type === "NEW_EMPLOYEE" ? (
					<NewEmployee />
				) : null}
			</div>
		</Backdrop>
	);
};

export default Modal;
