import { Outlet } from "react-router-dom";
import Sidebar from "./Sidebar";

const Layout = () => {
	return (
		<div className="settings">
			<Sidebar />

			<Outlet />
			{/* <Content /> */}
		</div>
	);
};

export default Layout;
