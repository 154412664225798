import { apiInstance, authInstance } from "./axios";

export const login = async (payload) => {
	try {
		// handle login
		const { data } = await authInstance.post("/auth/login", payload);

		if (data.success) {
			return data;
		}
	} catch (err) {
		if (err.response) {
			console.log(err);
			return err.response;
		}
	}
};

export const register = async (payload) => {
	try {
		// handle registering
		console.log("payload", payload);
		const { data } = await authInstance.post("/auth/register", payload);
		console.log("register res", data);

		if (data.success) {
			return data;
		}
	} catch (err) {
		if (err.response) {
			console.log(err.response);
			return err.response.data;
		}
	}
};

export const refreshAccessToken = async () => {
	try {
		// handle login
		const { data } = await apiInstance.get("/auth/refresh");

		if (data.success) {
			return data.data;
		}
	} catch (err) {
		if (err.response) {
			console.log(err);
		}
	}
};
