class HttpError extends Error {
	constructor(status, error, message) {
		super();
		this.status = status;
		this.error = error;
		this.message = message;
	}
}

export { HttpError };
