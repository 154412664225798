import { Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const Hero = () => {
	return (
		<div className="hero">
			<div className="hero_content">
				<Typography variant="h2">
					Spa Software that simplifies your life
				</Typography>
				<Typography variant="h5">
					Streamline your spa business and unlock its full potential with
					our all-in-one software solution
				</Typography>

				<div>
					<Link to="/free-trial">
						<Button variant="contained">Get a demo</Button>
					</Link>
				</div>
			</div>
		</div>
	);
};

export default Hero;
