import { apiInstance } from "./axios";

export const getUser = async (userid) => {
	try {
		const { data } = await apiInstance.get(`/users/${userid}`);
		console.log(data);

		if (data.success) {
			return data.data;
		}
	} catch (err) {
		if (err.response) {
			console.log(err);
			return err.response;
		}
	}
};
