import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col } from "rsuite";
import PaperContainer from "../../components/containers/PaperContainer";
import { Button, Typography } from "@mui/material";
import Card from "../../components/Card";
import CustomTable from "../../components/CustomTable";
import { openModal } from "../../features/modalSlice";
import { getUser } from "../../api/userApi";
import { selectUser } from "../../features/authSlice";
import MyTable from "../../components/tables/Table";

const Dashboard = () => {
	const dispatch = useDispatch();
	const { user } = useSelector(selectUser);

	const fetchUser = async () => {
		const res = await getUser(user.id);
		console.log("user response", res);
	};

	useEffect(() => {
		// fetchUser();
	}, []);

	const apptTableColumns = [
		{ dataKey: "firstName", title: "First Name", width: 150 },
		{ dataKey: "lastName", title: "First Name", width: 150 },
		{ dataKey: "phone", title: "Phone Number", width: 150 },
		{ dataKey: "email", title: "Email", width: 200 },
		{ dataKey: "dateTime", title: "Date and Time", width: 150 },
	];

	return (
		<div className="dashboard">
			<div className="dashboard_heading-container">
				<Typography variant="h5">Dashboard</Typography>
				<Button
					onClick={() => dispatch(openModal())}
					className="new_appt-button"
					variant="contained"
				>
					New Appointment
				</Button>
			</div>

			<PaperContainer>
				<Typography variant="h6">Appointments</Typography>
				<MyTable data={[]} columns={apptTableColumns} />
			</PaperContainer>

			{/* <Row>
				<Col md={6} sm={12}>
					<Card title="Appointments" />
				</Col>
				<Col md={6} sm={12}>
					<Card title="Clients served" />
				</Col>
			</Row> */}

			{/* <div className="dashboard_content">
				<div>sales overview</div>
				<div>appointments set for the day</div>
				<div>Online requests</div>
				<div>Leadership board</div>
			</div> */}
		</div>
	);
};

export default Dashboard;
