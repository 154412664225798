import React from "react";
import { CircularProgress } from "@mui/material";

function PageLoading() {
	return (
		<div className="spinner">
			<CircularProgress color="primary" />
		</div>
	);
}

export default PageLoading;
