import React from "react";
import { Link } from "react-router-dom";
import { Typography, Button } from "@mui/material";

function ContactSection() {
	return (
		<section className="contact_section">
			<div className="contact_section-title">
				<Typography variant="h4">Have Questions?</Typography>
				<Typography variant="body1">Get in touch. Let us help!</Typography>
			</div>
			<Link to="contact" className="contact_section-button">
				<Button variant="contained">Contact us</Button>
			</Link>
		</section>
	);
}

export default ContactSection;
