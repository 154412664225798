import { Paper, Card, Typography, Button } from "@mui/material";

const PriceCard = ({ heading, subheading, price }) => {
	return (
		<div className="price_card">
			<Typography variant="h5">{heading}</Typography>
			<Typography variant="body1">{subheading}</Typography>
			<div className="price_wrapper">
				<Typography variant="h3">{price}</Typography>
				<span>/ mo</span>
			</div>
			<Button variant="contained">Start Free Trial</Button>
		</div>
	);
};

export default PriceCard;
