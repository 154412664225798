import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, TextField, Typography, Grid } from "@mui/material";
import { closeModal } from "../../features/modalSlice";
import { selectModal } from "../../features/modalSlice";

const NewCustomer = () => {
	const { isOpen } = useSelector(selectModal);
	const dispatch = useDispatch();

	const [client, setClient] = useState({
		firstName: "",
		lastName: "",
		email: "",
		phone: "",
	});

	const handleChange = (e) => {
		const name = e.target.name;
		setClient({ ...client, [name]: e.target.value });
	};

	const clearForm = () => {
		setClient({
			firstName: "",
			lastName: "",
			email: "",
			phone: "",
		});
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		console.log(e.target);
		clearForm();
	};

	useEffect(() => {
		clearForm();
	}, [isOpen]);

	return (
		<div className="new_customer">
			<Typography
				variant="h5"
				sx={{ color: "#000", textAlign: "center", marginBottom: 4 }}
			>
				New Customer
			</Typography>
			<form onSubmit={handleSubmit} className="new_customer-form">
				<Grid container spacing={2}>
					<Grid item xs={12} sm={6}>
						<TextField
							autoComplete="given-name"
							name="firstName"
							required
							fullWidth
							id="firstName"
							label="First Name"
							value={client.firstName}
							onChange={handleChange}
							autoFocus
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							required
							fullWidth
							id="lastName"
							label="Last Name"
							name="lastName"
							value={client.lastName}
							onChange={handleChange}
							autoComplete="family-name"
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							required
							fullWidth
							id="email"
							label="Email Address"
							name="email"
							value={client.email}
							onChange={handleChange}
							autoComplete="email"
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							required
							fullWidth
							id="phone"
							label="Phone Number"
							name="phone"
							value={client.phone}
							onChange={handleChange}
							autoComplete="phone"
						/>
					</Grid>
				</Grid>

				<div className="button_group">
					<Button
						onClick={() => dispatch(closeModal())}
						variant="outlined"
					>
						Cancel
					</Button>
					<Button onClick={handleSubmit} variant="contained">
						Create
					</Button>
				</div>
			</form>
		</div>
	);
};

export default NewCustomer;
