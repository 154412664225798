import * as React from "react";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";

function Copyright(props) {
	return (
		<Typography
			variant="body2"
			color="text.secondary"
			align="center"
			{...props}
		>
			{"Copyright © "}
			<Link color="inherit" to="/">
				<Typography variant="body2">Selection Spa</Typography>
			</Link>{" "}
			{new Date().getFullYear()}
			{"."}
		</Typography>
	);
}

export default Copyright;
