import React, { useEffect } from "react";
import {
	Route,
	createBrowserRouter,
	createRoutesFromElements,
	RouterProvider,
} from "react-router-dom";
import { useDispatch } from "react-redux";
import PublicLayout from "./layouts/PublicLayout";
import Home from "./pages/home/Home";
import Features from "./pages/Features";
import Contact from "./pages/Contact";
import ProtectedLayout from "./layouts/ProtectedLayout";
import Dashboard from "./pages/portal/Dashboard";
import Profile from "./pages/portal/Profile";
import RequireAuth from "./routes/RequireAuth";
import Login from "./pages/auth/Login";
import ForgotPassword from "./pages/auth/ForgotPassword";
import Register from "./pages/auth/Register";
import Calendar from "./pages/portal/Calendar";
import Employees from "./pages/portal/Employees";
import Clients from "./pages/portal/clients/Clients";
import Settings from "./pages/portal/settings/index";
import Pricing from "./pages/Pricing";
import Demo from "./pages/Demo";
import DemoSignup from "./pages/DemoSignup";
import { initializeFromToken } from "./features/authSlice";

const router = createBrowserRouter(
	createRoutesFromElements(
		<Route>
			{/* Public routes */}
			<Route path="login" element={<Login />} />
			<Route path="register" element={<Register />} />
			<Route path="forgot-password" element={<ForgotPassword />} />
			<Route path="free-trial" element={<DemoSignup />} />

			<Route path="/*" element={<PublicLayout />}>
				<Route index element={<Home />} />
				<Route path="home" element={<Home />} />
				<Route path="features" element={<Features />} />
				<Route path="pricing" element={<Pricing />} />
				<Route path="contact" element={<Contact />} />
				<Route path="free-demo" element={<Demo />} />
			</Route>

			{/* Protected routes */}
			<Route element={<RequireAuth />}>
				<Route element={<ProtectedLayout />}>
					<Route index path="portal" element={<Dashboard />} />
					<Route path="calendar" element={<Calendar />} />
					<Route path="clients" element={<Clients />} />
					<Route path="profile" element={<Profile />} />
					<Route path="employees" element={<Employees />} />
					<Route path="settings" element={<Settings />} />
				</Route>
			</Route>
		</Route>
	)
);

const App = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		if (localStorage.getItem("refreshToken")) {
			dispatch(initializeFromToken());
		}
	}, []);

	return <RouterProvider router={router} />;
};

export default App;
