import React, { useState } from "react";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Copyright from "./Copyright";
import LOGO from "../../assets/logo.png";

const theme = createTheme();

export default function ForgotPassword() {
	const [email, setEmail] = useState("");

	const handleSubmit = async (event) => {
		event.preventDefault();

		// submit reset email
	};

	return (
		<div className="forgot_password-page">
			<ThemeProvider theme={theme}>
				<img src={LOGO} alt="logo" className="forgot_password-logo" />

				<Grid
					item
					xs={12}
					sm={8}
					md={5}
					component={Paper}
					elevation={6}
					square
				>
					<Box
						sx={{
							my: 8,
							mx: 4,
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
						}}
					>
						<Typography
							component="h1"
							variant="h5"
							style={{ marginTop: 50 }}
						>
							Forgot Password?
						</Typography>
						<Box
							component="form"
							noValidate
							onSubmit={handleSubmit}
							sx={{ mt: 1 }}
						>
							<TextField
								margin="normal"
								required
								fullWidth
								id="email"
								label="Email Address"
								name="email"
								autoComplete="email"
								autoFocus
							/>

							<Button
								type="submit"
								fullWidth
								variant="contained"
								sx={{ mt: 3, mb: 2 }}
							>
								Reset password
							</Button>
							<div className="back_btn-container">
								<Link to="/login">
									<Typography
										variant="body2"
										style={{ textDecoration: "underline" }}
									>
										Back
									</Typography>
								</Link>
							</div>

							<Copyright
								sx={{
									mt: 5,
									display: "flex",
									gap: 0.75,
									justifyContent: "center",
								}}
							/>
						</Box>
					</Box>
				</Grid>
			</ThemeProvider>
		</div>
	);
}
