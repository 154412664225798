import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
	Popover,
	Skeleton,
	Avatar,
	Typography,
	List,
	ListItem,
	Divider,
	ListItemButton,
	ListItemIcon,
	ListItemText,
} from "@mui/material";
import { Person, Logout } from "@mui/icons-material";
import { BsChevronDown } from "react-icons/bs";
import { logout } from "../features/authSlice";
import { selectUser } from "../features/authSlice";
import { AiFillCreditCard } from "react-icons/ai";

function Dropdown() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [anchorEl, setAnchorEl] = useState(null);
	const { user } = useSelector(selectUser);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
		console.log(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? "simple-popover" : undefined;

	const navigateToProfile = () => {
		setAnchorEl(null);
		navigate("/profile");
	};

	const handleLogout = () => {
		dispatch(logout());
		setAnchorEl(null);
		navigate("/login");
	};

	return (
		<>
			<div aria-describedby={id} onClick={handleClick} className="user">
				<div className="avatar_container">
					<Avatar />
				</div>
				<div>
					<Typography
						variant="body2"
						style={{ minWidth: 70, color: "rgb(189 196 209)" }}
					>
						{user ? user.title : <Skeleton variant="text" />}
					</Typography>
					<Typography variant="body1">
						{user ? user.name : <Skeleton variant="text" />}
					</Typography>
				</div>
				<BsChevronDown className="arrow_down-icon" />
			</div>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
			>
				<List>
					<ListItem
						sx={{ display: "flex", gap: 2 }}
						className="dropdown_user"
					>
						<Avatar />
						<div className="dropdown_user-info">
							<Typography variant="body1" sx={{ color: "#101828" }}>
								{user.name}
							</Typography>
							<Typography variant="body2" sx={{ color: "#667085" }}>
								{user.org}
							</Typography>
						</div>
					</ListItem>
					<Divider />
					<ListItem onClick={navigateToProfile} disablePadding>
						<ListItemButton>
							<ListItemIcon>
								<Person />
							</ListItemIcon>
							<ListItemText primary="Profile" />
						</ListItemButton>
					</ListItem>
					<Divider />
					<ListItem
						sx={{
							display: "flex",
							alignItems: "center",
							gap: 2,
						}}
						onClick={handleLogout}
						disablePadding
					>
						<ListItemButton>
							<ListItemIcon>
								<AiFillCreditCard />
							</ListItemIcon>
							<ListItemText primary="Billing" />
						</ListItemButton>
					</ListItem>
					<Divider />
					<ListItem
						sx={{
							display: "flex",
							alignItems: "center",
							gap: 2,
						}}
						onClick={handleLogout}
						disablePadding
					>
						<ListItemButton>
							<ListItemIcon>
								<Logout />
							</ListItemIcon>
							<ListItemText primary="Log out" />
						</ListItemButton>
					</ListItem>
				</List>
			</Popover>
		</>
	);
}

export default Dropdown;
