import * as React from "react";
import LOGO from "../assets/logo.png";

const DemoSignup = () => {
	return (
		<div className="demo_signup-page">
			<header className="demo_signup-header">
				<img src={LOGO} alt="logo" />
			</header>
			<section>This is the demo signup page</section>
		</div>
	);
};

export default DemoSignup;
