import { Typography } from "@mui/material";
import React from "react";

// Features - appointment booking, client management, inventory tracking, reporting and analysis

function Features() {
	return (
		<div>
			<Typography variant="h3"></Typography>
		</div>
	);
}

export default Features;
