import { Typography } from "@mui/material";
import LOGO from "../assets/logo.png";

export default function Footer() {
	return (
		<div className="footer">
			<div className="logo_wrapper">
				<img className="logo" src={LOGO} alt="logo" />
			</div>

			<Typography className="copyright-text" variant="body2">
				&copy; 2023 Selection Spa Software. All rights reserved.
			</Typography>
		</div>
	);
}
