import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = "http://localhost:8000";

export const config = {
	headers: {
		"Content-Type": "application/json",
	},
};

const baseQuery = fetchBaseQuery({
	baseUrl,
	prepareHeaders: (headers) => {
		// const token = SecureStore.getItemAsync("token");

		// if (token) {
		// 	headers.set("authorization", `Bearer ${token}`);
		// }

		return headers;
	},
});

export const api = createApi({
	reducerPath: "spaAppApi",
	baseQuery,
	endpoints: () => ({}),
});
