import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUser } from "../features/authSlice";
import Spinner from "../components/Spinner";

const RequireAuth = () => {
	const { user, isAuthenticated, isLoading } = useSelector(selectUser);
	const location = useLocation();

	if (user && isAuthenticated) {
		return isLoading ? <Spinner /> : <Outlet />;
	}

	return <Navigate to="/login" state={{ from: location }} replace />;
};

export default RequireAuth;
