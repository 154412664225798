import { Link, NavLink } from "react-router-dom";
import { Button, Typography } from "@mui/material";
import LOGO from "../assets/logo.png";

const SiteHeader = () => {
	const navigationItems = [
		{ label: "home", path: "/" },
		{ label: "Features", path: "/features" },
		{ label: "Pricing", path: "/pricing" },
		{ label: "Contact", path: "/contact" },
	];

	return (
		<header className="header">
			<img className="logo" src={LOGO} alt="logo" />
			<nav className="navigation">
				<ul className="navigation_list">
					{navigationItems.map((item, i) => {
						return (
							<li key={i} className="nav_item">
								<NavLink to={item.path}>
									<Typography variant="body1">{item.label}</Typography>
								</NavLink>
							</li>
						);
					})}
				</ul>
			</nav>

			<div className="action_buttons-container">
				<Link to="login">
					<Typography variant="body1">Staff login</Typography>
				</Link>
				<Link to="free-trial">
					<Button variant="contained">Book a demo</Button>
				</Link>
			</div>
		</header>
	);
};

export default SiteHeader;
