import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, TextField, Typography, Grid } from "@mui/material";
import { TimePicker, DatePicker } from "@mui/x-date-pickers";
import { closeModal } from "../../features/modalSlice";
import Select from "react-select";
import dayjs from "dayjs";
import { selectModal } from "../../features/modalSlice";

const NewAppointment = () => {
	const { isOpen } = useSelector(selectModal);
	console.log(isOpen);
	const dispatch = useDispatch();

	const [appointment, setAppointment] = useState({
		firstName: "",
		lastName: "",
		phone: "",
		email: "",
		gender: "Male",
		date: dayjs(new Date()),
		time: dayjs(new Date()),
		service: "",
	});

	const handleChange = (e) => {
		const name = e.target.name;
		setAppointment({ ...appointment, [name]: e.target.value });
	};

	const clearForm = () => {
		setAppointment({
			firstName: "",
			lastName: "",
			phone: "",
			email: "",
			gender: "Male",
			date: dayjs(new Date()),
			time: dayjs(new Date()),
			service: "",
		});
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		console.log(e.target);
		clearForm();
	};

	useEffect(() => {
		clearForm();
	}, [isOpen]);

	return (
		<div className="new_appointment">
			<Typography
				variant="h5"
				sx={{ color: "#000", textAlign: "center", marginBottom: 4 }}
			>
				New Appointment
			</Typography>
			<form onSubmit={handleSubmit} className="new_appt-form">
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Typography
							sx={{ color: "#000", fontWeight: 400, marginLeft: 2 }}
							variant="body1"
						>
							Contact Info
						</Typography>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							autoComplete="given-name"
							name="firstName"
							required
							fullWidth
							id="firstName"
							label="First Name"
							value={appointment.firstName}
							onChange={handleChange}
							autoFocus
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							required
							fullWidth
							id="lastName"
							label="Last Name"
							name="lastName"
							value={appointment.lastName}
							onChange={handleChange}
							autoComplete="family-name"
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							required
							fullWidth
							id="email"
							label="Email Address"
							name="email"
							value={appointment.email}
							onChange={handleChange}
							autoComplete="email"
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							required
							fullWidth
							id="phone"
							label="Phone Number"
							name="phone"
							value={appointment.phone}
							onChange={handleChange}
							autoComplete="phone"
						/>
					</Grid>
					<Grid item xs={12}>
						<Typography
							sx={{
								color: "#000",
								fontWeight: 400,
								marginLeft: 2,
							}}
							variant="body1"
						>
							Choose date and time
						</Typography>
					</Grid>
					<Grid item xs={12} sm={6}>
						<DatePicker
							label="Select Date"
							value={appointment.date}
							onChange={(newValue) =>
								setAppointment({ ...appointment, date: newValue })
							}
						/>
					</Grid>

					<Grid item xs={12} sm={6}>
						<TimePicker
							label="Select Time"
							value={appointment.time}
							onChange={(newValue) =>
								setAppointment({ ...appointment, time: newValue })
							}
						/>
					</Grid>
				</Grid>

				<div className="service_selection">
					<Typography
						variant="body1"
						sx={{ color: "#000", fontWeight: 400, marginLeft: 2 }}
					>
						Select Service
					</Typography>
					<Select isSearchable={true} isClearable={true} options={[]} />
				</div>

				{/* <div className="radio_buttons-container">
					<Typography variant="body1">Gender</Typography>

					<label>
						<input
							type="radio"
							value="Male"
							name="gender"
							onChange={(e) =>
								setAppointment({
									...appointment,
									gender: e.target.value,
								})
							}
							checked={appointment.gender === "Male"}
						/>
						Male
					</label>

					<label>
						<input
							type="radio"
							value="Female"
							name="gender"
							onChange={(e) =>
								setAppointment({
									...appointment,
									gender: e.target.value,
								})
							}
							checked={appointment.gender === "Female"}
						/>
						Female
					</label>
				</div> */}
				{/* <Typography variant="body1">Service</Typography>
				<select>
					<option value="something">something</option>
				</select> */}
				<div className="button_group">
					<Button
						onClick={() => dispatch(closeModal())}
						variant="outlined"
					>
						Cancel
					</Button>
					<Button onClick={handleSubmit} variant="contained">
						Create
					</Button>
				</div>
			</form>
		</div>
	);
};

export default NewAppointment;
