import { Paper } from "@mui/material";

const PaperContainer = ({ children }) => {
	return (
		<Paper elevation={1} sx={{ padding: 4 }}>
			{children}
		</Paper>
	);
};

export default PaperContainer;
