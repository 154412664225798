import axios from "axios";
import { refreshAccessToken } from "./authApi";
import { HttpError } from "./error";

const localhost = "http://localhost:8000/api";
const url = process.env.REACT_APP_BASE_URL;
const baseURL = process.env.NODE_ENV === "development" ? localhost : url;
// const baseURL = process.env.REACT_APP_BASE_URL;

export const authInstance = axios.create({
	baseURL,
	headers: {
		"Content-Type": "application/json",
	},
});

export const apiInstance = axios.create({
	baseURL,
	headers: {
		headers: {
			"Content-Type": "application/json",
		},
	},
});

apiInstance.interceptors.request.use(
	async function (config) {
		console.log(config.url);
		if (config?.url === "/auth/refresh") {
			console.log(config.url);
			const refreshToken = localStorage.getItem("refreshToken");
			config.headers.Authorization = `Bearer ${refreshToken}`;

			return config;
		} else {
			const accessToken = localStorage.getItem("accessToken");

			if (accessToken) {
				config.headers.Authorization = `Bearer ${accessToken}`;
			} else {
				console.log("no access token");
			}

			return config;
		}
	},
	function (err) {
		return Promise.reject(err);
	}
);

apiInstance.interceptors.response.use(
	(response) => {
		return response;
	},
	async function (err) {
		const refreshToken = localStorage.getItem("refreshToken");

		// if (!err.response) {
		// 	throw new Error(err);
		// }

		// if (err?.config?.url === "/auth/refresh") {
		// 	localStorage.removeItem("accessToken");
		// 	localStorage.removeItem("refreshToken");
		// 	window.location.href = "/";

		// 	throw new HttpError(
		// 		401,
		// 		"Unauthorized",
		// 		"User authentication required"
		// 	);
		// }

		if (err.response.status === 401 && refreshToken) {
			const accessToken = await refreshAccessToken();

			if (!accessToken) {
				localStorage.clear();
				window.location.href = "/login";

				throw new HttpError(
					401,
					"Unauthorized",
					"User authentication required"
				);
			}

			localStorage.setItem("accessToken", accessToken);
			err.config.headers.Authorization = `Bearer ${accessToken}`;

			return await apiInstance.request(err.config);
		}

		const accessToken = localStorage.getItem("accessToken");
		if (accessToken) {
			err.config.headers.Authorization = `Bearer ${accessToken}`;
			return await apiInstance.request(err.config);
		}
	}
);
