import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/authSlice";
import modalReducer from "../features/modalSlice";
import loadingReducer from "../features/loadingSlice";
import { api } from "../services";

export const store = configureStore({
	reducer: {
		auth: authReducer,
		modal: modalReducer,
		loading: loadingReducer,
		[api.reducerPath]: api.reducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware().concat(api.middleware),
});
