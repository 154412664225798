import React, { useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import { LoadingButton } from "@mui/lab";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Copyright from "./Copyright";
import { register } from "../../api/authApi";
import { selectUser, setCredentials } from "../../features/authSlice";
import LOGO from "../../assets/logo.png";

const theme = createTheme();

const validate = (values) => {
	const errors = {};

	// if (!values.email && !values.password) {
	// 	errors.email = "Field required";
	// 	errors.password = "Field required";
	// 	return errors;
	// }

	const valuesArray = Object.values(values);

	for (let value of valuesArray) {
		if (!value) {
			errors.message = "All fields required";
		}
	}

	if (!values.password) {
		errors.password = "You must create a password";
	}

	if (values.password !== values.confirmPassword) {
		errors.message = "Password must match";
		return errors;
	}

	if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
		errors.email = "Invalid email address";
	}

	return errors;
};

export default function SignUp() {
	const { user, isAuthenticated } = useSelector(selectUser);
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();

	let from = location.state?.from?.pathname || "/portal";

	const formik = useFormik({
		initialValues: {
			firstName: "",
			lastName: "",
			email: "",
			businessName: "",
			jobTitle: "",
			password: "",
			confirmPassword: "",
		},
		validate,
		onSubmit: async (values, actions) => {
			console.log(values);
			const res = await register(formik.values);

			if (res.success) {
				dispatch(setCredentials(res.data));
				actions.resetForm();
				navigate("/portal");
			}
		},
	});

	useEffect(() => {
		if (user && isAuthenticated) {
			navigate(from, { replace: true });
		}
	}, [user, isAuthenticated]);

	return (
		<ThemeProvider theme={theme}>
			<Container component="main" maxWidth="xs">
				<CssBaseline />
				<Box
					sx={{
						marginTop: 8,
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
					}}
				>
					<Link
						to="/"
						style={{
							margin: "0 0 60px 0",
						}}
					>
						<img src={LOGO} alt="logo" width="300" />
					</Link>
					{!!Object.keys(formik.errors).length && (
						<div
							style={{ marginBottom: 20 }}
							className="register_page-error"
						>
							{formik.errors.message}
						</div>
					)}
					<Typography component="h1" variant="h5">
						Sign up
					</Typography>
					<Box
						component="form"
						noValidate
						onSubmit={formik.handleSubmit}
						sx={{ mt: 3 }}
					>
						<Grid container spacing={2}>
							<Grid item xs={12} sm={6}>
								<TextField
									autoComplete="given-name"
									name="firstName"
									required
									fullWidth
									id="firstName"
									label="First Name"
									value={formik.values.firstName}
									onChange={formik.handleChange}
									autoFocus
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextField
									required
									fullWidth
									id="lastName"
									label="Last Name"
									name="lastName"
									value={formik.values.lastName}
									onChange={formik.handleChange}
									autoComplete="family-name"
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									required
									fullWidth
									id="email"
									label="Email Address"
									name="email"
									value={formik.values.email}
									onChange={formik.handleChange}
									autoComplete="email"
								/>
							</Grid>

							<Grid item xs={12}>
								<TextField
									required
									fullWidth
									id="businessName"
									label="Business Name"
									name="businessName"
									value={formik.values.businessName}
									onChange={formik.handleChange}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									required
									fullWidth
									id="jobTitle"
									label="Job Title"
									name="jobTitle"
									value={formik.values.jobTitle}
									onChange={formik.handleChange}
								/>
							</Grid>

							<Grid item xs={12}>
								<TextField
									required
									fullWidth
									name="password"
									label="Password"
									type="password"
									id="password"
									value={formik.values.password}
									onChange={formik.handleChange}
									autoComplete="new-password"
									// error={formik.errors.password ? true : false}
									// helperText={formik.errors.password}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									required
									fullWidth
									name="confirmPassword"
									label="Confirm Password"
									type="password"
									id="confirmPassword"
									value={formik.values.confirmPassword}
									onChange={formik.handleChange}
									// error={formik.errors.confirmPassword ? true : false}
									// helperText={formik.errors.confirmPassword}
								/>
							</Grid>

							<Grid item xs={12}>
								<Typography
									sx={{
										padding: "0px 15px",
										textAlign: "center",
									}}
									variant="body2"
								>
									By signing up, you agree to our Terms , Privacy
									Policy and Cookies Policy .
								</Typography>
								{/* <FormControlLabel
									control={
										<Checkbox
											value="allowExtraEmails"
											color="primary"
										/>
									}
									label="I want to receive inspiration, marketing promotions and updates via email."
								/> */}
							</Grid>
						</Grid>
						<LoadingButton
							loading={formik.isSubmitting}
							type="submit"
							fullWidth
							variant="contained"
							sx={{ mt: 3, mb: 2 }}
						>
							Sign Up
						</LoadingButton>
						{/* <Button
							type="submit"
							fullWidth
							variant="contained"
							sx={{ mt: 3, mb: 2 }}
						>
							Sign Up
						</Button> */}
						<Grid container justifyContent="flex-end">
							<Grid item>
								<Link
									style={{ textDecoration: "underline" }}
									to="/login"
								>
									<Typography variant="body2">
										Already have an account? Sign in
									</Typography>
								</Link>
							</Grid>
						</Grid>
					</Box>
				</Box>
				<Copyright
					sx={{
						mt: 5,
						display: "flex",
						gap: 0.75,
						justifyContent: "center",
					}}
				/>{" "}
			</Container>
		</ThemeProvider>
	);
}
