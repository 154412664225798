import React from "react";
import { Table } from "rsuite";
import "rsuite/dist/rsuite.min.css";

const { Column, HeaderCell, Cell } = Table;

function CustomTable() {
	return (
		<Table height={400} data={[]}>
			<Column width={60} align="center" fixed>
				<HeaderCell>Customer</HeaderCell>
				<Cell dataKey="email" />
			</Column>
			<Column width={60} align="center" fixed>
				<HeaderCell>Email</HeaderCell>
				<Cell dataKey="email" />
			</Column>
			<Column width={60} align="center" fixed>
				<HeaderCell>Phone</HeaderCell>
				<Cell dataKey="email" />
			</Column>
			<Column width={60} align="center" fixed>
				<HeaderCell>Start Time</HeaderCell>
				<Cell dataKey="email" />
			</Column>
		</Table>
	);
}

export default CustomTable;
