import { Sidenav, Nav } from "rsuite";

const Sidebar = () => (
	<div className="sidebar">
		<Sidenav>
			<Sidenav.Body>
				<Nav activeKey="1">
					{/* <Nav.Item eventKey="1" icon={<DashboardIcon />}>
						Settings
					</Nav.Item> */}
					<Nav.Item eventKey="1">Services</Nav.Item>
					<Nav.Item eventKey="2">Products</Nav.Item>
					<Nav.Item eventKey="3">Staff</Nav.Item>
					<Nav.Item eventKey="4">Manage Organization</Nav.Item>
					{/* <Nav.Menu eventKey="3" title="Advanced" icon={<MagicIcon />}> */}
					{/* <Nav.Item eventKey="3-1">Services</Nav.Item>
						<Nav.Item eventKey="3-2">Products</Nav.Item> */}
					{/* <Nav.Item eventKey="3-3">Loyalty</Nav.Item>
						<Nav.Item eventKey="3-4">Visit Depth</Nav.Item> */}
					{/* </Nav.Menu> */}
					{/* <Nav.Menu
						eventKey="4"
						title="Settings"
						icon={<GearCircleIcon />}
					>
						<Nav.Item eventKey="4-1">Applications</Nav.Item>
						<Nav.Item eventKey="4-2">Channels</Nav.Item>
						<Nav.Item eventKey="4-3">Versions</Nav.Item>
						<Nav.Menu eventKey="4-5" title="Custom Action">
							<Nav.Item eventKey="4-5-1">Action Name</Nav.Item>
							<Nav.Item eventKey="4-5-2">Action Params</Nav.Item>
						</Nav.Menu>
					</Nav.Menu> */}
				</Nav>
			</Sidenav.Body>
		</Sidenav>
	</div>
);

export default Sidebar;
