import { Typography } from "@mui/material";
import Hero from "../../components/Hero";
// import { Divider } from "@mui/material";
import ContactSection from "./sections/Contact";
import Services from "./sections/Services";

const Home = () => {
	return (
		<div className="landing">
			<Hero />
			<section className="solutions">
				<Typography variant="h4" className="solutions_title">
					Our Solutions
				</Typography>
				<div className="solutions_content">
					<div className="solutions_card">
						<Typography variant="h6">Appointment Scheduling</Typography>
						<Typography variant="body1">
							Our software allows spas to easily manage their appointment
							schedules, with features such as automated appointment
							confirmations, online booking, and staff scheduling.
						</Typography>
					</div>

					{/* <Divider orientation="vertical" /> */}
					<div className="solutions_card">
						<Typography variant="h6">Client Management</Typography>
						<Typography variant="body1">
							Keep track of all client information in one place,
							including appointment history, preferences, and contact
							details. Our software also allows you to send personalized
							communications to clients, such as birthday or anniversary
							emails.
						</Typography>
					</div>

					{/* <Divider orientation="vertical" /> */}

					<div className="solutions_card">
						<Typography variant="h6">Inventory Management</Typography>
						<Typography variant="body1">
							Our inventory management feature is fully integrated with
							the rest of our CRM software, so you can easily track
							product usage, manage stock levels, and make informed
							purchasing decisions. This helps ensure that your spa is
							always well-stocked and ready to meet the needs of your
							customers.
							{/* Our CRM software includes a built-in inventory management
							feature, which allows spas to track and manage their
							inventory levels in real-time. This helps spa owners and
							managers ensure that they always have the right products on
							hand, minimize waste, and avoid stockouts. With our
							inventory management solution, spas can also set up
							automated alerts to let them know when certain products are
							running low or when it's time to reorder. This helps to
							ensure that spas are always well-stocked and ready to serve
							their customers. */}
						</Typography>
					</div>
				</div>
			</section>
			{/* <section>sales overview</section>
			<div>appointments set for the day</div>
			<div>Online requests</div>
			<div>Leadership board</div> */}
			<Services />
			<ContactSection />
		</div>
	);
};

export default Home;
