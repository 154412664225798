import { Paper, Typography } from "@mui/material";
import PriceCard from "../components/PriceCard";

const Pricing = () => {
	return (
		<div className="pricing_page">
			<Typography className="heading" variant="h3">
				Choose the right plan for you.
			</Typography>
			<Typography className="subheading" variant="body1">
				Simple, straight forward pricing.
			</Typography>
			<div className="prices">
				<PriceCard
					heading="Essentials"
					subheading="Small teams"
					price="$149"
				/>
				<PriceCard heading="Deluxe" subheading="Small teams" price="$299" />
				<PriceCard
					heading="Enterprise"
					subheading="Small teams"
					price="$499"
				/>
			</div>
			<div className="contact_container">
				<Paper elevation={1}>
					<Typography variant="h5">Contact Us</Typography>
				</Paper>
			</div>
		</div>
	);
};

export default Pricing;
