import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Button, Card, FormLabel, Typography } from "@mui/material";
import PaperContainer from "../../components/containers/PaperContainer";
import BaseInput from "../../components/BaseInput";
import { selectUser } from "../../features/authSlice";
import { getUser } from "../../api/userApi";
import PageLoading from "../../components/PageLoading";

const Profile = () => {
	const [loading, setLoading] = useState(true);
	const { user } = useSelector(selectUser);
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [email, setEmail] = useState("");
	const [jobTitle, setJobTitle] = useState("");

	const handleChange = (e) => {};

	const fetchUser = async () => {
		const res = await getUser(user.id);
		console.log(res);
		setFirstName(res.firstName);
		setLastName(res.lastName);
		setEmail(res.email);
		setJobTitle(res.title);
		setLoading(false);
	};

	useEffect(() => {
		fetchUser();
	}, []);

	return (
		<React.Fragment>
			{loading ? (
				<PageLoading />
			) : (
				<div className="profile">
					<Typography variant="h5">Profile</Typography>
					<PaperContainer>
						<Card variant="outlined" className="card">
							<Typography variant="h6">Profile</Typography>
							<div>
								<div>
									<Typography variant="body2">First Name</Typography>
									<Typography variant="body1">Jonathan</Typography>
								</div>
								<div>
									<Typography variant="body2">Last Name</Typography>
									<Typography variant="body1">Lancaster</Typography>
								</div>
								<div>
									<Typography variant="body2">Email</Typography>
									<Typography variant="body1">
										lancast001@gmail.com
									</Typography>
								</div>
								<div>
									<Typography variant="body2">Job title</Typography>
									<Typography variant="body1">{jobTitle}</Typography>
								</div>
							</div>
						</Card>
					</PaperContainer>
				</div>
			)}
		</React.Fragment>
	);
};

export default Profile;
