import React from "react";
import ReactDOM from "react-dom/client";

// import "@fontsource/public-sans";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "./styles/main.scss";
import { CssBaseline } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import App from "./App";
import { store } from "./app/store";
import { Provider } from "react-redux";

const theme = createTheme({
	palette: {
		primary: {
			// main: "#b7faf0",
			main: "rgb(127,138,184)",
		},
	},
});

ReactDOM.createRoot(document.getElementById("root")).render(
	<React.StrictMode>
		<Provider store={store}>
			<CssBaseline />
			<ThemeProvider theme={theme}>
				<App />
			</ThemeProvider>
		</Provider>
	</React.StrictMode>
);
