import React, { useState, useEffect } from "react";
import { Paper } from "@mui/material";
import MoreIcon from "@rsuite/icons/legacy/More";
import {
	Table,
	Popover,
	Whisper,
	Checkbox,
	Dropdown,
	IconButton,
	Progress,
} from "rsuite";

const { Column, HeaderCell, Cell } = Table;

const CheckCell = ({ rowData, onChange, checkedKeys, dataKey, ...props }) => (
	<Cell {...props} style={{ padding: 0 }}>
		<div style={{ lineHeight: "46px" }}>
			<Checkbox
				value={rowData[dataKey]}
				inline
				onChange={onChange}
				checked={checkedKeys.some((item) => item === rowData[dataKey])}
			/>
		</div>
	</Cell>
);

const renderMenu = ({ onClose, left, top, className }, ref) => {
	const handleSelect = (eventKey) => {
		onClose();
		console.log(eventKey);
	};
	return (
		<Popover ref={ref} className={className} style={{ left, top }} full>
			<Dropdown.Menu onSelect={handleSelect}>
				<Dropdown.Item eventKey={1}>Edit</Dropdown.Item>
				<Dropdown.Item eventKey={2}>Delete</Dropdown.Item>
			</Dropdown.Menu>
		</Popover>
	);
};

const ActionCell = ({ rowData, dataKey, ...props }) => {
	return (
		<Cell {...props} className="link-group">
			<Whisper
				placement="autoVerticalStart"
				trigger="click"
				speaker={renderMenu}
			>
				<IconButton appearance="subtle" icon={<MoreIcon />} />
			</Whisper>
		</Cell>
	);
};

function MyTable({ data, columns, loading, maxWidth }) {
	const [checkedKeys, setCheckedKeys] = useState([]);
	const [checked, setChecked] = useState(false);
	const [indeterminate, setIndeterminate] = useState(false);
	// let checked = false;
	// let indeterminate = false;

	useEffect(() => {
		if (checkedKeys.length === data.length) {
			setChecked(true);
			// checked = true;
		} else if (checkedKeys.length === 0) {
			setChecked(false);
			// checked = false;
		} else if (checkedKeys.length > 0 && checkedKeys.length < data.length) {
			setIndeterminate(true);
			// indeterminate = true;
		}
	}, []);

	const handleCheckAll = (value, checked) => {
		const keys = checked ? data.map((item) => item.id) : [];
		setCheckedKeys(keys);
	};
	const handleCheck = (value, checked) => {
		const keys = checked
			? [...checkedKeys, value]
			: checkedKeys.filter((item) => item !== value);
		setCheckedKeys(keys);
	};

	return (
		<Paper sx={{ maxWidth: maxWidth }}>
			<Table loading={loading} data={data} id="table">
				<Column width={50} align="center">
					<HeaderCell style={{ padding: 0 }}>
						<div style={{ lineHeight: "40px" }}>
							<Checkbox
								inline
								checked={checked}
								indeterminate={indeterminate}
								onChange={handleCheckAll}
							/>
						</div>
					</HeaderCell>
					<CheckCell
						dataKey="id"
						checkedKeys={checkedKeys}
						onChange={handleCheck}
					/>
				</Column>
				{columns.map((item, i) => {
					return (
						<Column
							key={i}
							align="center"
							width={item.width}
							flexGrow={1}
						>
							<HeaderCell>{item.title}</HeaderCell>
							<Cell dataKey={item.dataKey} />
						</Column>
					);
				})}

				<Column width={120}>
					<HeaderCell>
						<MoreIcon />
					</HeaderCell>
					<ActionCell dataKey="id" />
				</Column>
			</Table>
		</Paper>
	);
}

export default MyTable;
