// import Sidebar from "./Sidebar";
import { Typography } from "@mui/material";
import Layout from "./Layout";

const Settings = () => {
	return (
		<Layout>
			<div className="content">
				<Typography variant="heading">Add Services</Typography>

				<div>
					<ul>
						<li>Name</li>
						<li>Price</li>
					</ul>
				</div>
				<div>
					<ul>
						<li>Color</li>
					</ul>
				</div>
			</div>
		</Layout>
	);
};

export default Settings;
