import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	isOpen: false,
	type: null, // NEW_APPOINTMENT | NEW_EMPLOYEE | NEW_CUSTOMER
};

const modalSlice = createSlice({
	name: "modal",
	initialState,
	reducers: {
		openModal: (state, action) => {
			state.isOpen = true;
			state.type = action.payload;
		},

		closeModal: (state, action) => {
			state.isOpen = false;
			state.type = null;
		},
	},
});

export const { openModal, closeModal } = modalSlice.actions;

export const selectModal = (state) => state.modal;

export default modalSlice.reducer;
