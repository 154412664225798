import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { LoadingButton } from "@mui/lab";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import axios from "axios";

const validate = (values) => {
	const errors = {};

	// if (!values.email && !values.password) {
	// 	errors.email = "Field required";
	// 	errors.password = "Field required";
	// 	return errors;
	// }

	const valuesArray = Object.values(values);

	for (let value of valuesArray) {
		if (!value) {
			errors.message = "All fields required";
		}
	}

	if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
		errors.email = "Invalid email address";
	}

	return errors;
};

export default function Contact() {
	const [success, setSuccess] = useState(false);

	const formik = useFormik({
		initialValues: {
			firstName: "",
			lastName: "",
			email: "",
			businessName: "",
			jobTitle: "",
			msg: "",
		},
		validate,
		onSubmit: async function (values, actions) {
			console.log("actions", actions);
			console.log(values);

			const localhost = "http://localhost:8000";
			const url = process.env.REACT_APP_BASE_URL;
			const config = { headers: { "Content-Type": "application/json" } };
			const { data } = await axios.post(
				`${localhost}/api/communications/contact-form`,
				formik.values,
				config
			);

			if (data.success) {
				setSuccess(true);
				actions.resetForm();

				setTimeout(() => {
					setSuccess(false);
				}, 10000);
			}
		},
	});

	return (
		<div className="contact">
			<Typography className="heading" variant="h3">
				Get in touch!
			</Typography>
			<Box
				component="form"
				noValidate
				onSubmit={formik.handleSubmit}
				sx={{ mt: 3, maxWidth: 440 }}
			>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={6}>
						<TextField
							autoComplete="given-name"
							name="firstName"
							required
							fullWidth
							id="firstName"
							label="First Name"
							value={formik.values.firstName}
							onChange={formik.handleChange}
							autoFocus
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							required
							fullWidth
							id="lastName"
							label="Last Name"
							name="lastName"
							value={formik.values.lastName}
							onChange={formik.handleChange}
							autoComplete="family-name"
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							required
							fullWidth
							id="email"
							label="Email Address"
							name="email"
							value={formik.values.email}
							onChange={formik.handleChange}
							autoComplete="email"
						/>
					</Grid>

					<Grid item xs={12}>
						<TextField
							fullWidth
							id="businessName"
							label="Business Name"
							name="businessName"
							value={formik.values.businessName}
							onChange={formik.handleChange}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							fullWidth
							id="jobTitle"
							label="Job Title"
							name="jobTitle"
							value={formik.values.jobTitle}
							onChange={formik.handleChange}
						/>
					</Grid>

					<Grid item xs={12}>
						<TextField
							multiline
							rows={4}
							required
							fullWidth
							name="msg"
							label="Message"
							id="message"
							value={formik.values.msg}
							onChange={formik.handleChange}
							autoComplete="message"
						/>
					</Grid>
				</Grid>
				<LoadingButton
					loading={formik.isSubmitting}
					type="submit"
					fullWidth
					variant="contained"
					sx={{ mt: 3, mb: 2 }}
				>
					Submit
				</LoadingButton>
			</Box>
			{/* <div style={{ height: 20 }}>
				<p>Test</p>
			</div> */}

			<div
				style={{
					width: 450,
					height: 30,
					marginBottom: 30,
					padding: 5,
				}}
			>
				{success && (
					<span className="contact_form-success">
						Thanks for contacting us. We'll be in touch shortly.
					</span>
				)}
				{!!Object.keys(formik.errors).length && (
					<span className="contact_form-error">
						{formik.errors.message}
					</span>
				)}
			</div>
		</div>
	);
}
