import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { ListItemText, Avatar } from "@mui/material";
import LOGO from "../assets/logo.png";
import { FiSettings } from "react-icons/fi";
import { IoIosNotifications } from "react-icons/io";
import { BsPeopleFill } from "react-icons/bs";
import { RiNumbersFill } from "react-icons/ri";
import { MdPeopleAlt, MdSell } from "react-icons/md";
import { AiFillHome, AiOutlineCalendar } from "react-icons/ai";
import CustomizedMenus from "./CustomizedMenus";
import { FiMoreHorizontal } from "react-icons/fi";
import User from "./User";

const Navbar = () => {
	const navigate = useNavigate();

	const navitems = [
		{
			id: "home",
			label: "Home",
			route: "portal",
			icon: <AiFillHome size="18" />,
			isAcive: false,
		},
		{
			id: "calendar",
			label: "Calendar",
			route: "calendar",
			icon: <AiOutlineCalendar size="18" />,
			isAcive: true,
		},
		{
			id: "clients",
			label: "Clients",
			route: "clients",
			icon: <BsPeopleFill size="18" />,
			isAcive: false,
		},
		{
			id: "sales",
			label: "Sales",
			route: "sales",
			icon: <MdSell size="18" />,
			isAcive: false,
		},
		{
			id: "reports",
			label: "Reports",
			route: "reports",
			icon: <RiNumbersFill size="18" />,
			isAcive: false,
		},
		{
			id: "employees",
			label: "Employees",
			route: "employees",
			icon: <MdPeopleAlt size="18" />,
			isAcive: false,
		},
	];

	const handleDynamicNavigation = (e) => {
		console.log(e.target.id);
	};

	return (
		<header className="dashboard_header">
			<div className="left_side-container">
				<div className="logo_container">
					<img src={LOGO} className="logo" alt="logo" />
				</div>

				<ul onClick={handleDynamicNavigation} className="navigation">
					{navitems.map((item) => {
						return (
							<li key={item.id} className="navigation_item">
								<NavLink id={item.id} to={`/${item.route}`}>
									{item.icon}
									<ListItemText primary={item.label} />
								</NavLink>
							</li>
						);
					})}

					<li className="navigation_item">
						<FiMoreHorizontal size="30" />
					</li>
				</ul>
			</div>

			<div className="right_center">
				{/* <Searchbar /> */}

				<div className="settings_notification-buttons">
					<span className="icon_wrapper">
						<IoIosNotifications size="20" />
					</span>
					<span
						onClick={(e) => navigate("settings")}
						className="icon_wrapper"
					>
						<FiSettings size="20" />
					</span>
				</div>
				{/* <Divider vertical /> */}

				<User />

				{/* <CustomizedMenus /> */}
			</div>
		</header>
	);
};

export default Navbar;
