import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import Navbar from "../components/Navbar";
import Spinner from "../components/Spinner";
import Modal from "../components/modals";
import Menu from "../components/Menu";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { selectUser } from "../features/authSlice";

const ProtectedLayout = () => {
	const { isLoading } = useSelector(selectUser);

	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			{isLoading ? (
				<Spinner />
			) : (
				<div className="portal">
					<Modal />
					<Spinner />
					<Navbar />
					{/* <Menu /> */}
					<Outlet />
				</div>
			)}
		</LocalizationProvider>
	);
};

export default ProtectedLayout;
