import { Typography } from "@mui/material";
import React from "react";
import {
	BsFillCalendarCheckFill,
	BsBarChartLineFill,
	BsFillPieChartFill,
} from "react-icons/bs";
import { LuMonitor } from "react-icons/lu";
import { IoPeople } from "react-icons/io5";

function Services() {
	const list = [
		{
			icon: <BsFillCalendarCheckFill size="30" />,
			label: "Appointment Scheduling",
		},
		{ icon: <LuMonitor size="30" />, label: "Front Desk Solutions" },
		{ icon: <IoPeople size="30" />, label: "Employee and Client Management" },
		{ icon: <BsBarChartLineFill size="30" />, label: "Sales" },
		{
			icon: <BsFillPieChartFill size="30" />,
			label: "Business Reporting & Analytics",
		},
	];

	return (
		<section className="service_icons-section">
			<ul className="service_list">
				{list.map((item) => {
					return (
						<li className="service_item">
							{item.icon}
							<Typography
								variant="body1"
								sx={{ fontWeight: 600, opacity: 0.9 }}
							>
								{item.label}
							</Typography>
						</li>
					);
				})}
			</ul>
		</section>
	);
}

export default Services;
