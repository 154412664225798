import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, TextField, Typography, Grid } from "@mui/material";
import { closeModal } from "../../features/modalSlice";
import Select from "react-select";
import { selectModal } from "../../features/modalSlice";

const NewEmployee = () => {
	const [roles, setRoles] = useState([]);
	const { isOpen } = useSelector(selectModal);
	const dispatch = useDispatch();

	const [employee, setEmployee] = useState({
		firstName: "",
		lastName: "",
		email: "",
		phone: "",
		role: "",
	});

	const handleChange = (e) => {
		const name = e.target.name;
		setEmployee({ ...employee, [name]: e.target.value });
	};

	const clearForm = () => {
		setEmployee({
			firstName: "",
			lastName: "",
			email: "",
			phone: "",
			role: "",
		});
	};

	const fetchRoles = async () => {
		// const res = await getRoles();
		const positions = [
			{ value: "manager", label: "Manager" },
			{ value: "administrator", label: "Administrator" },
		];

		setRoles(positions);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		console.log(e.target);
		clearForm();
	};

	useEffect(() => {
		fetchRoles();
	}, []);

	useEffect(() => {
		clearForm();
	}, [isOpen]);

	return (
		<div className="new_appointment">
			<Typography
				variant="h5"
				sx={{ color: "#000", textAlign: "center", marginBottom: 4 }}
			>
				New Employee
			</Typography>
			<form onSubmit={handleSubmit} className="new_appt-form">
				<Grid container spacing={2}>
					<Grid item xs={12} sm={6}>
						<TextField
							autoComplete="given-name"
							name="firstName"
							required
							fullWidth
							id="firstName"
							label="First Name"
							value={employee.firstName}
							onChange={handleChange}
							autoFocus
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							required
							fullWidth
							id="lastName"
							label="Last Name"
							name="lastName"
							value={employee.lastName}
							onChange={handleChange}
							autoComplete="family-name"
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							required
							fullWidth
							id="email"
							label="Email Address"
							name="email"
							value={employee.email}
							onChange={handleChange}
							autoComplete="email"
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							required
							fullWidth
							id="phone"
							label="Phone Number"
							name="phone"
							value={employee.phone}
							onChange={handleChange}
							autoComplete="phone"
						/>
					</Grid>
				</Grid>
				<div className="service_selection">
					<Typography
						variant="h6"
						sx={{ color: "#000", fontWeight: 400, marginLeft: 2 }}
					>
						Select position
					</Typography>
					<Select
						// styles={{
						// 	singleValue: (styles) => ({ ...styles, color: "#000" }),
						// }}
						isSearchable={true}
						isClearable={true}
						options={roles}
					/>
				</div>

				<div className="button_group">
					<Button
						onClick={() => dispatch(closeModal())}
						variant="outlined"
					>
						Cancel
					</Button>
					<Button onClick={handleSubmit} variant="contained">
						Create
					</Button>
				</div>
			</form>
		</div>
	);
};

export default NewEmployee;
