import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	isLoading: true,
};

const loadingSlice = createSlice({
	name: "loading",
	initialState,
	reducers: {
		setLoading: (state, action) => {
			state.open = action.payload;
		},
	},
});

export const { setLoading } = loadingSlice.actions;

export const selectLoading = (state) => state.loading;

export default loadingSlice.reducer;
