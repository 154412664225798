import React from "react";
import { InputAdornment, Typography } from "@mui/material";
import ContentContainer from "../components/containers/ContentContainer";

function Demo() {
	return (
		<ContentContainer>
			<InputAdornment />
			<Typography variant="h4">Try Our System For Free!</Typography>
		</ContentContainer>
	);
}

export default Demo;
