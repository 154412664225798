import React, { useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import { LoadingButton } from "@mui/lab";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Copyright from "./Copyright";
import { login } from "../../api/authApi";
import { setCredentials, selectUser } from "../../features/authSlice";

// Images
import LOGIN_BACKGROUND from "../../assets/login-bg.jpeg";
import LOGO from "../../assets/logo.png";

const theme = createTheme();

const validate = (values) => {
	const errors = {};

	if (!values.email && !values.password) {
		errors.email = "Field required";
		errors.password = "Field required";
		return errors;
	}

	if (!values.email) {
		errors.email = "Email Required";
	} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
		errors.email = "Invalid email address";
	}

	if (!values.password) {
		errors.password = "Password required";
	}

	return errors;
};

export default function Login() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const { user, isAuthenticated } = useSelector(selectUser);

	let from = location.state?.from?.pathname || "/portal";

	useEffect(() => {
		if (user && isAuthenticated) {
			navigate(from, { replace: true });
		}
	}, [user, isAuthenticated]);

	const formik = useFormik({
		initialValues: {
			email: "",
			password: "",
		},
		validate,
		onSubmit: async (values, actions) => {
			let from = location.state?.from?.pathname || "/portal";
			const res = await login(values);
			console.log(res);

			if (res.success) {
				dispatch(setCredentials(res.data));
				actions.resetForm();
				navigate(from, { replace: true });
			}
		},
	});

	// const inputRef = useRef();

	// let from = location.state?.from?.pathname || "/portal";

	// useEffect(() => {
	// 	inputRef.current.focus();
	// }, []);

	return (
		<ThemeProvider theme={theme}>
			<Grid container component="main" sx={{ height: "100vh" }}>
				<CssBaseline />

				<Grid
					item
					xs={false}
					sm={4}
					md={7}
					sx={{
						backgroundImage: `url(${LOGIN_BACKGROUND})`,
						backgroundRepeat: "no-repeat",
						backgroundColor: (t) =>
							t.palette.mode === "light"
								? t.palette.grey[50]
								: t.palette.grey[900],
						backgroundSize: "cover",
					}}
				/>

				<Grid
					item
					xs={12}
					sm={8}
					md={5}
					component={Paper}
					elevation={6}
					square
				>
					<Box
						sx={{
							my: 8,
							mx: 4,
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
						}}
					>
						<Link
							to="/"
							style={{
								margin: "30px 0 60px 0",
							}}
						>
							<img src={LOGO} alt="logo" width="300" />
						</Link>
						<Typography component="h1" variant="h5">
							Staff Login
						</Typography>

						<Box
							component="form"
							noValidate
							onSubmit={formik.handleSubmit}
							sx={{ mt: 1 }}
						>
							<TextField
								// ref={inputRef}
								error={formik.errors.email ? true : false}
								margin="normal"
								required
								fullWidth
								id="email"
								label="Email Address"
								name="email"
								autoComplete="email"
								autoFocus
								value={formik.values.email}
								onChange={formik.handleChange}
								helperText={formik.errors.email}
							/>
							<TextField
								error={formik.errors.password ? true : false}
								margin="normal"
								required
								fullWidth
								name="password"
								label="Password"
								type="password"
								id="password"
								value={formik.values.password}
								onChange={formik.handleChange}
								autoComplete="current-password"
								helperText={formik.errors.password}
							/>
							<FormControlLabel
								control={<Checkbox value="remember" color="primary" />}
								label="Remember me"
							/>

							<LoadingButton
								type="submit"
								fullWidth
								variant="contained"
								sx={{ mt: 3, mb: 2 }}
								loading={formik.isSubmitting}
							>
								Login
							</LoadingButton>

							{/* <Button
								type="submit"
								fullWidth
								variant="contained"
								sx={{ mt: 3, mb: 2 }}
							>
								Login
							</Button> */}
							<Grid container>
								<Grid item xs>
									<Link
										style={{ textDecoration: "underline" }}
										to="/forgot-password"
										variant="body2"
									>
										<Typography variant="body2">
											Forgot password?
										</Typography>
									</Link>
								</Grid>
								<Grid item>
									<Link
										style={{ textDecoration: "underline" }}
										to="/register"
									>
										<Typography variant="body2">
											{"Don't have an account? Sign Up"}
										</Typography>
									</Link>
								</Grid>
							</Grid>
							<Copyright
								sx={{
									mt: 5,
									display: "flex",
									gap: 0.75,
									justifyContent: "center",
								}}
							/>
						</Box>
					</Box>
				</Grid>
			</Grid>
		</ThemeProvider>
	);
}
