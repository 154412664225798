import { useState } from "react";
import { useSelector } from "react-redux";
import { Backdrop, CircularProgress } from "@mui/material";
import { selectUser } from "../features/authSlice";

const Spinner = () => {
	const { isLoading } = useSelector(selectUser);
	const [open, setOpen] = useState(false);

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<Backdrop
			sx={{
				color: "#fff",
				background: "#fff",
				zIndex: (theme) => theme.zIndex.drawer + 1,
			}}
			open={isLoading}
			// onClick={handleClose}
		>
			<CircularProgress color="primary" />
		</Backdrop>
	);
};

export default Spinner;
